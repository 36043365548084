// Builders.js

import styled, { createGlobalStyle, css, keyframes } from 'styled-components'
import {
    breakpoints,
} from '../common/constants'

export const colours = {
    lightBlue: '#a3c1f5',
    ultraLightBlue: '#bad1f7',
}

/* strategy: mobile first */
/* iPhone viewport width: 375px */
/* breakpoints:
    xs: 376,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1920
 */
export const mediaScreen = {
    xs: (...args) => css`
        @media only screen and (max-width: ${breakpoints.xs}px) {
            ${css(...args)};
        }
    `,
    sm: (...args) => css`
        @media only screen and (min-width: ${breakpoints.xs + 1}px) {
            ${css(...args)};
        }
    `,
    md: (...args) => css`
        @media only screen and (min-width: ${breakpoints.sm}px) {
            ${css(...args)};
        }
    `,
    lg: (...args) => css`
        @media only screen and (min-width: ${breakpoints.md}px) {
            ${css(...args)};
        }
    `,
    xl: (...args) => css`
        @media only screen and (min-width: ${breakpoints.lg}px) {
            ${css(...args)};
        }
    `,
    xxl: (...args) => css`
        @media only screen and (min-width: ${breakpoints.xl}px) {
            ${css(...args)};
        }
    `,
}

export const GlobalStyles = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
    }
    *, *::after, *::before {
        box-sizing: border-box;
    }
    body {
        height: 100vh;
        width: 100vw;
        text-align: center;
        overflow-x: hidden; /* to enable shifting of menu out of view */
    }
    a, a:visited {
        // color: salmon;
        color: ${colours.lightBlue};
        color: ${colours.ultraLightBlue};
    }
    a:hover {
        // color: white;
        color: salmon;
    }
    main {
        background: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // font-size: calc(10px + 2vmin);
        font-size: .95rem;
        color: white;
        ${mediaScreen.lg`
            font-size: 1.33rem;

        `}
    }
    .header {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
    }
    
    .App-link {
        color: #61dafb;
    }
    
    pre {
        text-align: left;
        font-size: .85rem;
    }
    
    ul {
        text-align: left;
        font-size: 1rem;
    }
    
    li {
        text-align: left;
    }

    ol > li {
        padding: 0 1rem 1.1rem 1rem;
    }
    
    .link,
    li > a,
    li > a:visited {
        // color: aliceblue;
        // color: cornflowerblue;
        color: ${colours.lightBlue};
        color: ${colours.ultraLightBlue};
    }

    /* add spacing on small devices */
    li:has(> a) {
        padding-bottom: 1.33rem;
        ${mediaScreen.lg`
            padding-bottom: .33rem;
        `}
    }

    code {
        font-size: .9rem;
    }

    .mt-2 {
        margin-top: calc(.5 * 2rem);
    }
    .mb-2 {
        margin-bottom: calc(.5 * 2rem);
    }
    .my-2 {
        margin-top: calc(.5 * 2rem);
        margin-bottom: calc(.5 * 2rem);
    }
    .mt-1 {
        margin-top: calc(.25 * 2rem);
    }
    .mb-1 {
        margin-bottom: calc(.25 * 2rem);
    }
    .my-1 {
        margin-top: calc(.25 * 2rem);
        margin-bottom: calc(.25 * 2rem);
    }

    @media screen {
        .print-only {
            display: none;
        }
    }

    @media print {
        .screen-only {
            display: none;
        }
    }

    @media print {
        header, aside {
            display: none;
        }
        body {
            height: auto;
            width: auto;
            text-align: center;
            overflow-x: auto;
        }
        footer.print-only {
            display: block;
            font-size: .9rem;
            // color: #1c4286;
            color: #333;
            margin-top: 1rem;
        }
        main {
            // width: 100% !important;
            // padding: 0 !important;
            // margin: 0 !important;
            margin: .9rem !important;
            padding: .9rem !important;
            border: 1px solid grey;
            background-color: transparent;
            min-height: auto;
            display: block;
            align-items: left;
            // font-size: 1.33rem;
            font-size: 1.1rem;
            color: black;
        }
        h1 {
            border-bottom: 1px solid grey;
            padding: .3rem;
            margin: .3rem;
            // background-color: #abc;
        }
        .prevnextlink {
            display: none;
        }
    }
`

export const SearchContainer = styled.div`
    position: relative;
    display: block;
    margin-top: 60px;
`

export const SearchForm = styled.form`
    // display: flex;
`

export const Searchfield = styled.input`
    border-radius: 3px;
    border: 3px solid salmon;
    // margin: 1rem;
    margin: 1rem 0;
    // padding: .5rem 3rem .5rem .5rem;
    // padding: .5rem 3rem;
    // padding: 9px 3rem .5rem;
    padding: .5rem 2.33rem .5rem .75rem;
    display: block;
    font-size: 1.5rem;
    color: #282c34;
    // flex: 1;
    width: 100%;
`

export const MagnifyingGlass = styled.div`
    display: block;
    position: absolute;
    // right: 1rem;
    // top: 1.7rem;
    width: 3rem;

    text-align: right;
    top: 1.67rem;
    font-size: 1.67rem;
    // right: .1rem;
    right: .4rem;
    color: black;
`

export const SearchSection = styled.section`
    display: flex;
    // width: 800px;
    flex-direction: column;
    // border: 1px solid salmon;
    border-radius: 3px;
    min-height: 90vh;

    width: 360px; /* mobile first default width to fit iphone 8 (375px) */
    // &:before { content: ' [xs] '; }

    ${mediaScreen.sm`
        width: 376px;
        // &:before { content: ' [sm] '; }
    `}
    ${mediaScreen.md`
        width: 576px;
        // &:before { content: ' [md] '; }
    `}
    ${mediaScreen.lg`
        width: 600px;
        // &:before { content: ' [lg] '; }
    `}
    ${mediaScreen.xl`
        width: 992px;
        // &:before { content: ' [xl+] '; }
    `}

`

export const SearchDiv = styled.div`
    // flex: 1;
`

export const StyledByIngredCont = styled.div`
    padding: 1rem;
`

export const MainHeader = styled.h1`
    // margin-block-end: 1.33em;
    margin-block-end: 1em;
`

export const Header1 = styled.h1`
    text-align: center;
    margin-top: 70px; /* so not covered by hamburger */
    padding: 0 .1rem;
    // background: slategrey; /* DEBUG */
    font-size: 1.3rem;
    ${mediaScreen.sm`
        font-size: 1.34rem;
    `}
    ${mediaScreen.md`
        padding: 0 .1rem 1rem .1rem;
    `}
    ${mediaScreen.lg`
    `}
    ${mediaScreen.xl`
    `}

    @media print {
        margin: 1rem;
        padding-bottom: 1rem;
    }
`

export const Header1Large = styled(Header1)`
    font-size: 2rem; /* normal h1 size */
`

export const Header2 = styled.h2`
    text-align: center;
`

export const Header3 = styled.h3`
    text-align: center;
`

export const Header4 = styled.h4.attrs(props => ({
    align: props.align || 'center'
}))`
    text-align: ${props => props.align};
    margin-block-end: .3em;
    // margin-block-start: 1em;
`

export const RecipeUL = styled.ul`
    text-align: left;
    font-size: 1rem;
    display: flex;
    // width: 800px;
    width: 360px; /* mobile first default width to fit iphone 8 (375px) */
    flex-wrap: wrap;
    /* list-style-type: square; */
    list-style-type: none;
    border: 0px solid salmon;
    text-indent: 0;
    padding: 0;
    justify-content: center; /* center the content items */

    ${mediaScreen.sm`
        width: 376px;
        // &:before { content: ' sm '; }
    `}
    ${mediaScreen.md`
        width: 576px;
        // &:before { content: ' md '; }
    `}
    ${mediaScreen.lg`
        width: 600px;
        // &:before { content: ' lg '; }
    `}
    ${mediaScreen.xl`
        width: 992px;
        // &:before { content: ' xl+ '; }
    `}

    @media print {
        width: auto;
        margin: 3rem;
        & a, a:visited, a:hover {
            color: black;
        }
        & li {
            border: 1px solid lightgrey;
        }
    }

`

export const RecipeLI = styled.li`
    margin: .3rem;
    /* padding: .3rem; */
    padding: .6rem;
    text-align: left;
    width: 350px;
    /* width: calc((1000px / 3) - .6rem); */
    border: 1px solid rgb(250, 128, 114);
    border-color: rgba(250, 128, 114, 0.4);
    text-indent: 0;

    // &:after { content: ' 350'; }

    ${mediaScreen.sm`
        width: 360px;
        // &:after { content: ' 360'; }
    `}
`

/* cf https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/ */
export const StyledMenu = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: ivory;
    // transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(150%)'}; /* NOT WORKING hiding it to the right causes overflow */
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-110%)'}; /* TODO: why does hiding off screen right cause overflow? */
    top: 4rem;
    height: calc(100vh - 4rem);
    text-align: left;
    position: absolute;
    right: 0.7rem;
    left: -0.7rem;
    transition: transform 0.3s ease-in-out;
    padding: 1rem 2rem;

    li {
        padding-right: 0;
        padding-left: 3px;
        text-align: left;
    }

    a {
        font-size: 2rem;
        padding: 1rem .2rem;
        font-weight: bold;
        color: #282c34;
        text-decoration: none;
        transition: color 0.3s linear;

        @media (max-width: 576px) {
              font-size: 1.5rem;
              text-align: center;
        }
    
        &:hover {
            color: #343078;
            text-decoration: underline;
        }
    }

    /* attempted overflow fix 20240609 */
    @media (max-width: 576px) {
        width: 100%;
    }

`

export const StyledBurger = styled.button`
    position: absolute;
    top: 30px;
    top: 1rem;
    right: 0.7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2.4rem;
    height: 3rem;
    border: none;
    cursor: pointer;
    padding: 6px 3px;
    // z-index: 10;

    &:focus {
        outline: none;
    }

    div { // div inside the burger
        width: 2.05rem;
        height: 0.375rem;
        background: #282c34;
        border-radius: 0;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
        color: ivory;

        :first-child {
            // transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
            transform: ${({ open }) => open ? 'rotate(40deg) translateX(1px)' : 'rotate(0)'};
            width: ${({ open }) => open ? '2.25rem' : '2.05rem'};
        }
    
        :nth-child(2) {
            opacity: ${({ open }) => open ? '0' : '1'};
            transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
        }
    
        :nth-child(3) {
            // transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
            transform: ${({ open }) => open ? 'rotate(-40deg) translateX(1px)' : 'rotate(0)'};
            width: ${({ open }) => open ? '2.25rem' : '2.05rem'};
        }
    }
`

export const FactorSection = styled.section`
    display: flex;
    flex-direction: column;
    // margin: 1rem 6rem;
    padding-bottom: 1.33rem;
    ${mediaScreen.md`
        flex-direction: row;
    `}
`
export const FactorCell = styled.div`
    // border: 1px solid crimson;
    flex: ${props => props?.flex ? props?.flex : 1};
    text-align: center;

    ${mediaScreen.md`
        text-align: right;

        /* adjacent siblings selector, to select the second cell */
        & + & {
            // border: 3px solid blue;
            text-align: left;
            display: flex;
        }
    `}
`
export const FactorTextInner = styled.div`
    // border: 1px solid green;
    padding: .33rem;
    margin-left: 15%;
    margin-right: 15%;
    ${mediaScreen.md`
        padding: 1rem;
        // border: 3px solid yellow;
        margin: 0;
    `}
`

export const FactorSpanWithX = styled.span`
    position: relative;
    &:after {
        content: '\u274C'; /* U+274C Cross Mark Unicode Character (red) */
        position: absolute;
        top: 3px;
        right: 3px;
    }
    ${mediaScreen.md`
        &:after {
            background: aliceblue;
            right: 0;
            top: 0;
            border: 1px solid black;
        }
    `}
`

export const FactorInput = styled.input.attrs(props => ({
    maxlength: props.maxlength || 9,
    size: props.size || 9,
}))`
    text-align: center;
    font-size: 2rem;
    // display: block;
    ${mediaScreen.md`
        flex: 1;
        margin: .5rem;
        font-size: inherit;
    `}
`
// // export const FactorText = styled.div`
// //     font-size: 0.9rem;
// //     flex: 3;
// //     padding: .67rem .67rem 0 0;
// //     text-align: right;
// //     border: 1px solid crimson;
// // `
// export const FactorCell = styled.div`
//     position: relative;
//     height: 4rem;
//     border: 1px solid green;
//     ${mediaScreen.md`
//         flex: ${props => props?.flex ? props?.flex : 1};
//     `}
// `
// export const FactorTextInner = styled.div`
//     position: absolute;
//     margin: 0;
//     top: 50%;
//     right: 1rem;
//     transform: translateY(-50%);
//     font-size: 0.9rem;
//     // padding: .67rem .67rem 0 0;
//     padding: 0 .67rem 0 0;
//     text-align: right;
//     text-align: left;
//     border: 1px solid crimson;
// `
// export const FactorInput = styled.input.attrs(props => ({
//     maxlength: props.maxlength || 9,
//     size: props.size || 9,
// }))`
//     flex: 1;
//     font-size: 1.3rem;
//     padding-left: .2rem;
//     text-align: center;

//     position: absolute;
//     margin: 0;
//     top: 50%;
//     left: 0;
//     transform: translateY(-50%);
// `

export const FactorButton = styled.button`
    flex: 1;
    font-size: 1rem;
    background: ivory;
    margin: .3rem 0 .3rem 1rem;
    padding: .2rem;
    border-radius: 3px;
    border: 2px solid salmon;
`

export const FABContainer = styled.div`
    position: relative;
`

export const FABOuter = styled.div`
    position: fixed;
    background: ${props => props.activated ? 'salmon' : 'grey'};
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    color: ivory;
    bottom: 1rem;
    right: 1rem;
    border: 1px solid ivory;

    font-size: 2rem;
    width: 3rem;
    height: 3rem;
    line-height: 1.33rem;
    box-shadow: 3px 3px 12px rgba(100, 149, 237, 0.6);

    ${mediaScreen.sm`
        
    `}
    ${mediaScreen.md` /* scr width >= 576 */

    `}
    ${mediaScreen.lg` /* scr width >= 768 */
        padding: -1rem -1rem; /* ?????? */
        bottom: 3rem;
        right: 3rem;
        border: 3px solid ivory;
        width: 6rem;
        height: 6rem;
        font-size: 6rem;
        line-height: 3rem;
        font-weight: 900;
        box-shadow: 0 10px 25px rgba(100, 149, 237, 0.6);
    `}
`

export const FABInner = styled.div`
    display: flex;
    // flex: 1;
    flex-direction: column;
    justify-content: center; /* center-align children vertically*/
    text-align: center; /* horizontal center alignment */
    width: 100%; /* nec for text-alignment */
    height: 100%; /* nec for text-alignment */
`

export const spinAnimation = keyframes`
    0%      { transform: rotate(0deg); }
    100%    { transform: rotate(360deg); }
`

export const Spinner = styled.div`
    border: 10px solid transparent;
    border-top: 10px solid salmon;
    border-radius: 50%;
    width: ${props => props.width || 80}px;
    height: ${props => props.height || 80}px;
    // animation: spin 1s linear infinite;
    animation-name: ${spinAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
`

export const ContainerSpinnerCentered = styled.div.attrs(props => ({
    // width: props.width && !isNaN(parseInt(props.width)) ? 40,
    width:  props.width  && typeof parseInt(props.width)  === 'number' ? props.width  / 2 : 40,
    height: props.height && typeof parseInt(props.height) === 'number' ? props.height / 2 : 40,
}))`
    position: fixed;
    top:  calc(50% - ${props => props.height || 80}px);
    left: calc(50% - ${props => props.width || 80}px);
`

export const MetaDiv = styled.div`
    margin-bottom: 1rem;
    text-align: left;
    margin-left: 3rem;
`

export const ULSources = styled.ul`
    font-size: .9rem;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
`

export const LISources = styled.li`
    font-size: .9rem;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
`

export const StyledQRCodeCont = styled.div`
    display: inline-flex; /* cf https://stackoverflow.com/a/33722030/23517902 */
    align-items: center;
`

export const StyledQRCodeContScr = styled.div`
    display: inline-flex; /* cf https://stackoverflow.com/a/33722030/23517902 */
    // border: 1px solid salmon;
    align-items: center;
    margin-bottom: 1rem;
`

export const StyledQRCodeModalCont = styled.div`
    display: inline-flex; /* cf https://stackoverflow.com/a/33722030/23517902 */
    border: 1.5rem solid white;
    align-items: center;
    margin: 1rem;

    @media screen {
        border: 1.5rem solid white;
    }

    @media print {
        // display: none;
    }
`

export const StyledQRCode = styled.div`
    flex: 1;
    background: white;
    padding: 1rem;
    // border: .33rem solid salmon;
    height: 100%;
    width: 100%;
`

export const StyledQRCodeScr = styled.div`
    flex: 1;
    background: transparent;
    padding: .33rem 1rem;
    border: 0px solid salmon;
    height: 100%;
    width: 100%;
    text-align: right;
    cursor: pointer;
`

export const StyledQRCodeInfo = styled.div`
    flex: 3;
    background: transparent;
    text-align: left;
    font-size: 1rem;
    padding-right: 1rem;
`
