// Recipe.js

import React, { useCallback, useEffect, useState, useContext } from 'react'
import {
    Link,
    useLocation,
    useParams,
} from 'react-router-dom'
import styled from 'styled-components'
import QRCode from 'react-qr-code'
// import { sleep } from '../common/functions'
import {
    Header2,
    FactorInput,
    FactorSection,
    MetaDiv,
    ULSources,
    LISources,
    mediaScreen,
    colours,
    Header1,
    FactorTextInner,
    FactorCell,
    FactorSpanWithX,
    StyledQRCodeCont,
    StyledQRCode,
    StyledQRCodeScr,
    StyledQRCodeInfo,
    StyledQRCodeContScr,
    StyledQRCodeModalCont,
} from './Builders'
import { RecipeContext } from '../App'
import {
    dbGetRecipeById,
    dbGetRecipeBySlug,
    // decimaliseFactor,
    decimaliseFraction,
    reformatExtra,
    sleep,
} from '../common/functions'
import { DEBUG } from '../common/constants'
import usePrevNextStruct from '../hooks/usePrevNextStruct'
// import { useLocalStorage } from '../hooks/useLocalStorage'

export const Recipe = props => {

    const params = useParams()
    const { id, slug } = params || {}

    const [ loaded,   setLoaded ]   = useState(false)
    const [ recipe,   setRecipe ]   = useState({})
    const [ recipeId, setRecipeId ] = useState(id)
    const [ factor,   setFactor ]   = useState()
    const [ notFound, setNotFound ] = useState(false)
    const [ prevPrevNext, setPrevPrevNext ] = useState([])
    const [ nextPrevNext, setNextPrevNext ] = useState([])
    const [ showQRCode,   setShowQRCode ]   = useState(false)

    const {
        recipeTitles,
        factorBtn,
        storedFactor, setStoredFactor,
    } = useContext(RecipeContext)

    /* grab the Link state vars, incl "prev", "next" IF set in a component like SortableTitles */
    const { state } = useLocation()
    const { prev, next } = state || {}
    // console.log(62, { prev, next })

    const prevNextStruct = usePrevNextStruct(recipeTitles)
    // console.log(65, { prevNextStruct })

    useEffect(() => {
        // console.log(77, 
        //     recipeTitles.find(e => e?.slug === prev),
        //     recipeTitles.find(e => e?.slug === next),
        //     { prevNextStruct },
        // )
        if (    recipeTitles && Array.isArray(recipeTitles)
            &&  prevNextStruct
            &&  (prev || next)
        ) {
            const prevId = (recipeTitles.find(e => e?.slug === prev) || {})?._id
            const nextId = (recipeTitles.find(e => e?.slug === next) || {})?._id
            // console.log(80, { prevId, nextId })
            setPrevPrevNext(prevNextStruct?.[prevId])
            setNextPrevNext(prevNextStruct?.[nextId])
        }
    }, [prevNextStruct, recipeTitles, prev, next])

    useEffect(() => {
        document.title = 'RECIPE'
    }, [])

    useEffect(() => {
        setFactor(decimaliseFraction(storedFactor))
    }, [storedFactor, setFactor])

    const getRecipeById = useCallback(async () => {
        // await sleep(3000)
        if (!recipeId) return
        const result = await dbGetRecipeById(recipeId)
        // console.log(64, result)

        /* if no recipe, i.e. wrong id given (e.g. recipe has been deleted or replaced) */
        if (!result) {
            setNotFound(true)
            setLoaded(true)
        }

        setRecipe(result instanceof Array ? result[0] : result)
        // setLoaded(true)
    }, [recipeId])

    const getRecipeBySlug = useCallback(async () => {
        // await sleep(3000)
        if (!slug) return
        const result = await dbGetRecipeBySlug(slug)
        // console.log(91, result)

        /* if no recipe, i.e. wrong id given (e.g. recipe has been deleted or replaced) */
        if (!result) {
            setNotFound(true)
            setLoaded(true)
        }

        setRecipe(result instanceof Array ? result[0] : result)
    }, [slug])

    useEffect(() => {
        // getRecipe()
        getRecipeById()
    }, [getRecipeById]) /* NB dependency must be the const function */

    useEffect(() => {
        getRecipeBySlug()
    }, [getRecipeBySlug]) /* NB dependency must be the const function */

    useEffect(() => {
        setLoaded(true)
        const { title } = recipe || {}
        if (title) document.title = `${title} - Recipe Collection`
    }, [recipe])

    const {
        title,
        meta,
        parts,
    } = recipe || {}
    
    if (parts && parts instanceof Array) parts[0].title = null // remove "__MAIN__"
    const metadata = meta instanceof Array 
        ? meta.reduce((acc, curr) => {
            acc[curr[0]] = curr[1]
            return acc
        }, {}) 
        : {}
    const { lang } = metadata || {}

    const onChangeFactor = evt => {
        const n = evt.target.value.trim()
        setStoredFactor(n)
        return setFactor(decimaliseFraction(n))
    }

    const onBlurFactor = () => {
        if (factor === null) return
    }

    const handleClickX = () => setStoredFactor('')

    // const handleQRClick = () => setShowQRCode(prev => setShowQRCode(!prev))
    const handleQRClick = e => {
        console.log(173, 'clicked', e)
        setShowQRCode(prev => !prev)
    }

    return (
        <section
            id="recipe-container"
            // style={{ transition: 'height 1750ms ease-in-out', height: loaded ? 'auto' : 0 }}
        >
            {
                DEBUG
                    ? (
                        <>
                            {/* <pre>{JSON.stringify({ factorBtn, storedFactor, factor }, null, 2)}</pre> */}
                            {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
                            {/* <pre>recipeId: {recipeId}</pre> */}
                            {/* <pre>recipe._id: {recipe?._id}</pre> */}
                            {/* <pre>{JSON.stringify({ prevPrevNext, nextPrevNext }, null, 2)}</pre> */}
                        </>
                    )
                    : null
            }
            {
                loaded && parts instanceof Array
                    ? (
                        <>
                            <Header1 className={DEBUG ? 'Header1' : ''}>
                                {
                                    prev
                                        ? (<PrevOrNextLink
                                            {...{ type:'prev', slug: prev, _id: recipe?._id, prevNext: prevPrevNext, }}
                                            onClick={() => setLoaded(false)}
                                        />) 
                                        : null
                                }
                                {title}&nbsp;{lang && lang === 'de' ? '(in German)' : ''}
                                {
                                    next
                                        ? (<PrevOrNextLink
                                            {...{ type:'next', slug: next, _id: recipe?._id, prevNext: nextPrevNext }}
                                            onClick={() => setLoaded(false)}
                                        />)
                                        : null
                                }
                            </Header1>

                            <div className="screen-only">
                                {/* <h1>Screen only!!!</h1> */}
                                <StyledQRCodeContScr>
                                    {/* <span>showQRCode={showQRCode?'true':'false'}</span> */}
                                    <StyledQRCodeScr className='screen-only' onClick={handleQRClick}>
                                        <QRCode
                                            value={window.location?.href}
                                            size={64}
                                            // onClick={handleQRClick}
                                        />
                                    </StyledQRCodeScr>
                                    <StyledQRCodeInfo>
                                        Tap to show QR code
                                    </StyledQRCodeInfo>
                                </StyledQRCodeContScr>
                            </div>

                            <div className="print-only">
                                {
                                window.location?.href
                                    ? (
                                        <StyledQRCodeCont>
                                            <StyledQRCode>
                                                <QRCode value={window.location?.href} size={128} />
                                            </StyledQRCode>
                                        </StyledQRCodeCont>
                                    )
                                    : null
                                }
                                {
                                window.location?.href
                                    ? <div className="mt-1 mb-2"><code>{window.location?.href}</code></div>
                                    : null
                                }
                            </div>

                            {
                            recipe && 'meta' in recipe
                                ? <Meta meta={recipe['meta']}/>
                                : null
                            }

                            {
                            factorBtn
                            ? (
                                <FactorSection className={DEBUG ? 'FactorSection' : ''}>
                                    <FactorCell
                                        flex={3}
                                        className={DEBUG ? 'FactorCell' : ''}
                                    >
                                        <FactorTextInner className={DEBUG ? 'FactorTextInner' : ''}>
                                            Change the recipe amounts by a certain factor (e.g. 3 or 1/2): 
                                        </FactorTextInner>
                                    </FactorCell>
                                    <FactorCell
                                        flex={1}
                                        className={DEBUG ? 'FactorCell' : ''}
                                    >
                                        <FactorSpanWithX
                                            onClick={handleClickX}
                                        >
                                            <FactorInput
                                                className={DEBUG ? 'FactorInput' : ''}
                                                type='text'
                                                maxLength='9'
                                                size='6'
                                                autoFocus={true}
                                                autocomplete="off"
                                                autocorrect="off"
                                                autocapitalize="off"
                                                spellcheck={false}
                                                onChange={onChangeFactor}
                                                onBlur={onBlurFactor}
                                                value={storedFactor}
                                            />
                                        </FactorSpanWithX>
                                    </FactorCell>
                                </FactorSection>
                            )
                            : null
                            }
                            {
                            parts.map((e, i) => <Part key={`part-${i}`} { ...parts[i] } n={i} factor={factor} />) 
                            }

                            <Modal
                                isModalOpen={showQRCode}
                                onClose={handleQRClick}
                                header={`Recipe QR code`}
                                title={title}
                            >
                                <StyledQRCodeModalCont>
                                    <QRCode
                                        value={window.location?.href}
                                        size={256}
                                    />
                                </StyledQRCodeModalCont>
                                <div className="mt-1 mb-2"><code>{window.location?.href}</code></div>
                            </Modal>

                            {/* {
                                DEBUG
                                    ? <pre>{JSON.stringify(recipeTitles, null, 2)}</pre>
                                    : null
                            } */}

                        </>
                    )
                    : (notFound)
                        ? (<>
                            <p>-= [ Recipe not found! ] =-</p>
                            Check the <Link to={'/list'}>list of recipes</Link>, &nbsp;
                            or <Link to={'/search'}>search for one</Link>.
                        </>)
                        : (
                            <>
                                <p>loading ...</p>
                                {/* <div>slug:{ slug ? <pre>{slug}</pre> : null }</div>
                                <div>id:{ slug ? <pre>{id}</pre> : null }</div> */}
                            </>
                        )
            }

            {/* {
                DEBUG
                    ? <pre>{recipe ? JSON.stringify(recipe, null, 2) : null}</pre>
                    : null
            } */}
            
        </section>
    )
}

const Part = ({ title = null, ingreds = null, method = null, n = 0, factor = null }) => {

    const amtsAsDecimal = ingreds.map(({ qty: { amt=0 } }, i) => {
        if (typeof amt === 'number') return amt
        if (typeof amt === 'string') {
            return decimaliseFraction(amt)
        }
        return 0
    })

    return (
        <>
            {/* <pre>{ingreds ? JSON.stringify(ingreds, null, 2) : null}</pre> */}
            {
                ingreds
                    ? (
                        <IngredientContainer
                            className={DEBUG ? 'IngredientContainer' : ''}
                        >
                            {   title && <Header2 className='Header3'>{title}</Header2> }
                            {
                                ingreds.map(({ name='', qty: { amt='', unit='' }, extra='' }, i) => {
                                    unit = unit === 'x' ? '' : `${unit}\u00A0`
                                    amt = factor && typeof amtsAsDecimal[i] !== 'undefined'
                                        ? (amtsAsDecimal[i] * factor) > 100
                                            ? Math.round(amtsAsDecimal[i] * factor)
                                            : Math.round((amtsAsDecimal[i] * factor)*100)/100
                                        : amt
                                    return (
                                        <IngredientRow
                                            key={`ingred-${n}-${i}`}
                                            className={DEBUG ? 'IngredientRow' : ''}
                                        >
                                            <StyledAmtUnit>{amt}&nbsp;{unit}</StyledAmtUnit>
                                            <StyledIngredDetail>
                                                <div>
                                                    {name}
                                                    <StyledIngredDetailExtra>
                                                        {/* &nbsp; */}
                                                        {
                                                            extra ? reformatExtra(extra) : null
                                                        }
                                                    </StyledIngredDetailExtra>
                                                </div>
                                            </StyledIngredDetail>
                                        </IngredientRow>
                                    )
                                })
                            }
                        </IngredientContainer>
                    )
                    : null
            }
            {
                method
                    ? (
                        <MethodContainer className='MethodContainer'>
                        {
                            method.map((e, i) => (
                                <li key={`meth-${n}-${i}`}>{e}</li>
                            ))
                        }
                        </MethodContainer>
                    )
                    : null
            }
        </>
    )
}

const Meta = ({ meta = [] }) => {

    // console.log(232, {meta})
    const srcArr    = meta.filter(e => e[0]==='src').map(e => e[1])
    const infoArr   = meta.filter(e => e[0]==='info').map(e => e[1])
    // const categArr  = meta.filter(e => e[0]==='categ').map(e => e[1])

    // const outputArr = arr => arr.map((e, i) => <h3 key={`${rndStr()}-${i}`}>{e}</h3>)

    return (
        <MetaDiv>
            {/* <pre>{srcArr.length     ? JSON.stringify(srcArr,    null, 2) : null}</pre>
            <pre>{infoArr.length    ? JSON.stringify(infoArr,   null, 2) : null}</pre>
            <pre>{categArr.length   ? JSON.stringify(categArr,  null, 2) : null}</pre>
            {srcArr.length     ? outputArr(srcArr)      : null}
            {infoArr.length    ? outputArr(infoArr)     : null}
            {categArr.length   ? outputArr(categArr)    : null} */}
            {
                infoArr.length
                    ? (
                        infoArr.map((e, i) => <div key={`infop-${i+1}`}>{e}</div>)
                    )
                    : null
            }
            {
                srcArr.length === 0
                    ? null
                    : srcArr.length === 1
                        ? <code>Source: {srcArr[0]}</code>
                        : (
                            <>
                                <code>Sources:</code>
                                <ULSources>
                                    {srcArr.map((e, i) => <LISources key={`srcs-${i+1}`}>{e}</LISources>)}
                                </ULSources>
                            </>
                        ) 
            }
        </MetaDiv>
    )
}

const PrevOrNextLink = ({ type = 'next', slug = null, _id = null, prevNext = null }) => {
    if (!slug) return null
    const [ prev, next ] = Array.isArray(prevNext) ? prevNext : []
    return type === 'prev'
        ? ( <StyledPrevNextLink
                title={slug}
                className={`StyledPrevNextLink-${_id}`}
                to={`/${slug}`}
                // state={{ _id }}
                state={{ prev, next, }}
                // onClick={() => setLoaded(false)}
            >&lt;&lt;&nbsp;&nbsp;</StyledPrevNextLink>
        )
        : ( <StyledPrevNextLink
                title={slug}
                className={`StyledPrevNextLink-${_id}`}
                to={`/${slug}`}
                // state={{ linkedid: _id }}
                state={{ prev, next, }}
                // onClick={() => setLoaded(false)}
            >&nbsp;&nbsp;&gt;&gt;</StyledPrevNextLink>
        )
}

const Modal = ({ isModalOpen = false, onClose, header = null, title = null, children }) => {
    if (isModalOpen !== true) {
        return null
    }
    return (
        <StyledModal
            // className='screen-only'
            onClick={onClose}
        >
            <StyledModalXCont>
                <StyledModalX
                    onClick={() => onClose}
                >X</StyledModalX>
            </StyledModalXCont>
            <h1>{header || 'Scan QR code for recipe'}</h1>
            <h2>{title}</h2>
            <>
                {children}
            </>
        </StyledModal>
    )
}

const StyledModalXCont = styled.div`
    position: relative;
    width: 100%;
    cursor: pointer;
`
const StyledModalX = styled.div`
    position: absolute;
    top: 0;
    right: 3rem;
    width: auto;
    padding: 0 1rem;
    font-size: 3rem;
    font-weight: 900;
    border: .3rem solid white;
    border-radius: 3rem;
    text-align: center;
`

const StyledModal = styled.section`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    // background-color: rgba(0, 0, 0, .65);
    // background-color: rgba(0, 0, 0, .85);
    background-color: rgba(40, 44, 52, .95);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1rem solid salmon;
`

const IngredientContainer = styled.div`
    @media print {
        // border: 10px solid green;
        margin: 1rem 0 2rem 0;
    }
    @media screen {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        margin-bottom: 2rem;
    }
`

const IngredientRow = styled.div`
    @media print {
        // border: 5px solid magenta;
        display: flex;
    }
    @media screen {
        display: flex;
        flex-direction: row;
        justify-content: center;
        border: 1px solid salmon; /* effectively first row gets all borders */
        // border-top: 1px solid salmon;
        // border-right: 1px solid salmon;
        // border-left: 1px solid salmon;
        // border-bottom: 1px solid salmon;
        // width: 680px;
        // width: 360px;
        width: 360px; /* mobile first default width to fit iphone 8 (375px) */
        margin: auto; /* centers it horizontally */
        padding-bottom: .5rem;
        padding-top: .5rem;

        /* all rows but the first have top border removed to prevent doubling the width */
        & + & {
            border-top: 0;
        }
    }

    ${mediaScreen.lg`
        font-size: 1.33rem;
        width: 680px;
    `}
`
/* iPhone viewport width: 375px */
/* breakpoints:
    xs: 376,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1920
 */
const MethodContainer = styled.ol`
    // width: 860px;
    width: 370px; /* iphone 8 width: 375px */
    margin: auto auto 2rem auto; /* auto centers it horizontally */
    // margin: auto; /* auto centers it horizontally */
    // margin: auto; /* auto centers it horizontally */

    /* debug */
    &:before {
        // content: ' xs ';
        font-size: 2rem;
        font-weight: 900;
        border: 3px solid cornflowerblue;
        margin: 1rem;
        padding: 1rem;
        display: block;
    }

    ${mediaScreen.sm`
        width: 376px;
        // &:before { content: ' sm '; }
    `}
    ${mediaScreen.md`
        width: 576px;
        // &:before { content: ' md '; }
    `}
    ${mediaScreen.lg`
        width: 600px;
        // &:before { content: ' lg '; }
    `}
    ${mediaScreen.xl`
        width: 992px;
        // &:before { content: ' xl+ '; }
    `}

    @media print {
        width: auto;
        margin: 3rem 4rem 3rem 3rem;
    }
`

const StyledIngred = styled.div`
    font-weight: 500;
    flex: 1;
    @media print {
        flex: 1;
        // border: 1px solid crimson;
        margin: .167rem .167rem;
    }
`

const StyledAmtUnit = styled(StyledIngred)`
    flex: 1;
    text-align: right;
`

const StyledIngredDetail = styled(StyledIngred)`
    flex: 2;
    text-align: left;
    @media print {
        flex: 2;
        // border: 1px solid black;
    }
`

const StyledIngredDetailExtra = styled.span`
    font-weight: normal;
    // font-weight: 300;
    // color: crimson;
`

const StyledPrevNextLink = styled(Link)`
    text-decoration: none;
    // color: cornflowerblue;
    color: ${colours.lightBlue};

`
